import React from 'react';
import fshLogo from '../images/logos/FSH.png';
import kcyhaLogo from '../images/logos/kcyha.png';
import lvsLogo from '../images/logos/lvs_logo_responsive.png';


class Volunteer extends React.Component {
    render = () => 
    <article className="volunteer">
        <h2>Volunteer Experience</h2>
        <section className="ed-gallery">
            
            <div className="single-ed">
            <div className="single-ed-img">
                <img src={fshLogo} alt="Centriq Full Stack Hack Mentor Badge" />                
            </div>
            <div className="single-ed-desc">
                <h3>Full Stack Hackathon<br/>Mentor</h3>
                <p>Centriq Training | Aug 2020</p>
                
            </div>
            </div>
            <div className="single-ed" id="kcyha">
            <div className="single-ed-img">
                <img src={kcyhaLogo} alt="Kansas City Youth Hockey Association Logo" />                
            </div>
            <div className="single-ed-desc">
                <h3>Hockey Coach</h3>
                <p>Kansas City Youth Hockey | 2012-Current</p>
                
            </div>
            </div>
            <div className="single-ed" id="lvs">
            <div className="single-ed-img">
                <img src={lvsLogo} alt="Lawrence Virtual School Logo" />                
            </div>
            <div className="single-ed-desc">
                <h3>Hour of Code Club Leader</h3>
                <p>Lawrence Virtual School | 2020-Current</p>
                
            </div>
            </div>
        </section>
    </article>
}

export default Volunteer;