import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

class Testimonials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: {
                name: "Tony Lee",
                role: "Operations Engineering Team Lead, Garmin",
                desc: "First and foremost, James is just a great person to work with. He always has a smile on his face and carries himself in a very respectful and positive manner. James has an excellent ability to multi-task and manage specific details of each task with a high level of detail. He has excellent communication skills that focus on careful listening and positive delivery in a timely fashion. Finally, James takes responsibility and ownership for the work he does and is able to take feedback from others to continuously improve himself and his work."
            }
        }
    }
    render = () =>
        <section className="testimonials">
            <div className="color-band">
                <h3 className="band-heading">What They Say...</h3>
            </div>
            <div className="t-gallery">
                <p className="desc">
                <span className="quote"><FontAwesomeIcon icon={['fas', 'quote-left']} /></span>
                    {this.state.testimonial.desc}
                <span className="quote"><FontAwesomeIcon icon={['fas', 'quote-right']} /></span>
                </p>
                <div className="t-name">
                    <h4>{this.state.testimonial.name}</h4>
                    <p>{this.state.testimonial.role}</p>
                </div>
            </div>
        </section>

}

export default Testimonials;