import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)


class TechSkills extends React.Component {
    render = () =>
        <article className="skills">
            <h2>Tech Skills</h2>
            <p className="intro">In the past 3 years of development experience, I have learned the below languages/technologies which have allowed me to become a more efficient developer. Beyond the technologies that I have picked up, I pride myself on innovation and problem-solving. If there are technologies to learn, I thrive on curiosity and perseverance to pick up new concepts and implement them to bring valuable solutions to my clientele.</p>

            <hr />
            <section className="tech-gallery">
                <div className="front-end">
                    <h3>Front End</h3>
                    <ul>
                        <li><img src="https://img.icons8.com/color/96/000000/html-5.png" alt="HTML5 Logo" title="HTML5"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/css3.png" alt="CSS3 Logo" title="CSS3"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/javascript.png" alt="JavaScript logo" title="JavaScript"/></li>
                        <li><img src="https://img.icons8.com/plasticine/100/000000/react.png" alt="React Logo" title="ReactJS"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/bootstrap.png" alt="Bootstrap Logo" title="Bootstrap"/></li>
                        <li><img src="https://img.icons8.com/ios-filled/100/000000/jquery.png" title="jQuery" alt="jQuery logo"/></li>
                      
                    </ul>



                </div>
                <div className="middle-tier">
                    <h3>Middle Tier</h3>
                    <ul>
                        <li><img src="https://img.icons8.com/color/96/000000/c-sharp-logo.png" alt="C#" title="C#"/></li>
                        <li><img src="https://img.icons8.com/windows/96/000000/asp.png" alt="ASP.NET" title="ASP.NET"/></li>
                        <li><img src="https://img.icons8.com/windows/96/000000/nodejs.png" alt="Node.js" title="node.js"/></li>
                    </ul>
                </div>
                <div className="back-end">
                    <h3>Back End</h3>
                    <ul>
                        <li><img src="https://img.icons8.com/color/96/000000/firebase.png" title="Firebase" alt="Firebase logo"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/microsoft-sql-server.png" alt="Sql Server" title="SQL Server"/></li>
                        <li><img src="https://img.icons8.com/nolan/96/sql.png" alt="SQL" title="SQL"/></li>
                        <li><img src="https://img.icons8.com/ios-filled/100/000000/json.png" alt="JSON" title="JSON"/></li>
                        <li><img src="https://img.icons8.com/ios-filled/96/000000/xml.png" title="XML" alt="XML"/></li>
                    </ul>
                </div>
                <div className="general-tech">
                    <h3>General Tech Skills</h3>
                    <ul>
                        <li><img src="https://img.icons8.com/color/96/000000/npm.png" alt="Node Package Manager" title="Node Package Manager"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/visual-studio.png" alt="Visual Studio" title="Visual Studio"/></li>
                        <li><img src="https://img.icons8.com/color/96/000000/trello.png" alt="Trello" title="Trello"/></li>
                        <li><img src="https://img.icons8.com/color/100/000000/office-365.png" alt="Office 365" title="Office 365"/></li>
                        <li><img src="https://img.icons8.com/windows/96/000000/github.png" alt="GitHub logo" title="GitHub"/></li>
                    </ul>
                </div>
            </section>

        </article>
}

export default TechSkills;