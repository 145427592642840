import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import EditProject from './EditProject';
import ProjectBrief from './ProjectBrief';

library.add(far, fas, fab);


class SingleProject extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            site: '',
            brief: '',
            showEdit: false,
            showBrief: false
        }
    }
    

    deleteProject = e => {
        this.props.deleteProject(e);
    }

    updateProject = e =>{
        this.props.updateProject(e);
    }

    showProject = () => {
      this.state.showEdit === false ? this.setState({showEdit: true}) : this.setState({showEdit:false});
    }

    showBrief = () => {
        this.state.showBrief === false ? this.setState({showBrief: true}) : this.setState({showBrief: false})
    }
    render = () =>
    
    <div className="single-project-container">
    <div className="single-project" id={this.props.projects.name} style={{ backgroundImage: `url(${this.props.image})`}}>
        {this.props.role == "Admin" &&
        <div className="admin">
         <button className="delete" onClick={() => this.props.deleteProject(this.props.index)} title="Delete Project">
                &times;
            </button>
            <button className="edit" onClick={this.showProject} title="Edit Project">
            <FontAwesomeIcon icon={['far', 'edit']} />
            </button>
        </div>
        }
        <h1>{this.props.name}</h1>
        <div className="projectLink" id={this.props.tech}>
            <a onClick={this.showBrief} >Project Bio</a>
            <a href={this.props.url} target="_blank" rel="noopener">Go To Site</a>
        </div>
    
        </div>      
    {this.state.showEdit == true &&
        <EditProject projects={this.props.projects[this.props.index]} show={this.state.showEdit} onHide={this.showProject} showBrief={this.state.showBrief} onHideBrief={this.showBrief} index={this.props.index} update={this.props.updateProject} name={this.props.name}  desc={this.props.desc} tech={this.props.tech} url={this.props.url} image={this.props.image} brief={this.props.brief}/>
    }

{this.state.showBrief == true &&
        <ProjectBrief projects={this.props.projects[this.props.index]} show={this.state.showEdit} onHide={this.showProject} showBrief={this.state.showBrief} onHideBrief={this.showBrief} index={this.props.index} update={this.props.updateProject} name={this.props.name}  desc={this.props.desc} tech={this.props.tech} url={this.props.url} image={this.props.image} brief={this.props.brief}/>
    }
  
    </div>

 
}

export default SingleProject;