import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(far);

const Login = (props) => (
    <div className="login">
        <span>Login for key functionality on the Resources and Projects links.</span>
        <br/>
        <button className="github btn btn-color" onClick={() => props.authenticate()}>Login with <FontAwesomeIcon icon={['fab', 'google']} />oogle</button>
    </div>
);

export default Login;
