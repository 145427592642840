import React from 'react';
import About from './About';
import Social from './Social';
import Testimonials from './Testimonials';
import Skills from './Skills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "James Caldwell",
            role: "<Web Developer />",
            tag: "Leverage the digital marketplace for your company..."
        }

    }


    render = () =>
        <React.Fragment>
            <section className="home">
                <div className="section-wrapper">
                    <article> 
                        <h1 className="title">{this.state.name}</h1>
                        <h2 className="subtitle">{this.state.role}</h2>
                        <Social />
                        <h2 className="tagline">{this.state.tag}</h2>
                        <div className="scroll center">
                            <p>Scroll for more about me...</p>
                            <p><FontAwesomeIcon icon={['fas', 'chevron-circle-down']}/></p>
                        </div>
                    </article>
                   
                </div>
            </section>
            <About />
            <Skills />
            <Testimonials />
        </React.Fragment>
}

export default Home;