import React from 'react';
import SingleProject from './SingleProject';


class Projects extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            technology: "All"
        }
    }


    changeFilter = x => {
        this.setState({technology: x}); 
        var button = document.getElementById('All');
        button.classList.remove('active');
        
    }
    render = () =>
    <div className="background">
        <section className="projects">
            <div className="heading-overlay">
            <h1 className="clear-text">Projects</h1>
            </div>
            <article className="gallery">
                <h1>{this.state.technology}</h1>
                <div id="gallery-menu">
                <button onClick={() => this.changeFilter('All')} className="active" id="All">All</button>
                <button onClick={() => this.changeFilter("ASP.NET MVC")} id="ASP.NET MVC">ASP.NET MVC</button>
                <button onClick={() => this.changeFilter("ReactJS")} id="ReactJS">ReactJS</button>
                <button onClick={() => this.changeFilter("CMS")} id="CMS">CMS</button>
                <button onClick={() => this.changeFilter("SQL")} id="SQL">SQL</button>
                <button onClick={() => this.changeFilter("C#")} id="C#">C#</button>
                <button onClick={() => this.changeFilter(".NET CORE")} id=".NET CORE">.NET CORE</button>
                </div> 
                <hr/>
                {this.state.technology === "All" &&
            Object.keys(this.props.projects).map(key =>
                <SingleProject projects={this.props.projects} key={this.props.projects[key].id} index={key} name={this.props.projects[key].name} tech={this.props.projects[key].tech} url={this.props.projects[key].siteURL} brief={this.props.projects[key].brief} image={this.props.projects[key].image} now={0} role={this.props.role} />
                )}
            {this.state.technology != "All" &&
            Object.keys(this.props.projects).filter(x => this.props.projects[x].tech === this.state.technology).map(key =>
                <SingleProject projects={this.props.projects} key={this.props.projects[key].id} index={key} name={this.props.projects[key].name} tech={this.props.projects[key].tech} url={this.props.projects[key].siteURL} image={this.props.projects[key].image} brief={this.props.projects[key].brief} now={0} role={this.props.role}/>
                )}
    </article>
        </section>

        </div>
}

export default Projects;