const projects = [
    {
        name: "E-Commerce", 
        category: "ASP.NET MVC",
        description: "This is an ASP.NET MVC application",
        url:"http://ustore.jamesrcaldwell.com",
        image: "./src/images/projects/storefront.jpg",
        useCase: "This will be a long description of technical concepts. Under Construction"
    },
    {
        name: "Reservation System", 
        category: "ASP.NET MVC",
        description: "This is an ASP.NET MVC application",
        url:"http://fsdp.jamesrcaldwell.com",
        image: "./src/images/projects/storefront.jpg",
        useCase: "This will be a long description of technical concepts. Under Construction"
    },
    {
        name: "StoreFront",
        category: "React", 
        description: "This is an ASP.NET MVC application",
        url:"http://jamesrcaldwell.com",
        image: "./src/images/projects/storefront.jpg",
        useCase: "This will be a long description of technical concepts. Under Construction"
    }
]

export default projects;