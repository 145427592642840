import React from 'react';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            elevatorPitch: <React.Fragment>
                <div className="about-desc">
                 <p>I worked for close to a decade in the non-profit sector, leading large teams of volunteers and crafting programs based on the needs of the clientele I was serving...in those days, mainly young families. In these positions, I learned many lessons about leadership, reliability, and teamwork. While I remained quite successful, I needed a career field where it was necessary that I continue to <em>learn new things everyday</em>.</p>
              
                <p>That's where Centriq comes in: I attended <a href="https://centriq.com" target="_blank" className="color">Centriq Training</a> from April - July 2018 as a student in the Full Stack Web Developer track. Upon completion of that training in ASP.NET MVC, I began working at Houlihans Restaurants Inc., as a Mid-Level Front End Developer in a contract role. There I crafted 12 websites for <a className="color text-bold" href="https://bristolseafoodgrill.com" target="_blank" rel="noreferrer">Bristol Seafood Grill</a>, <a className="color" href="https://devonseafood.com" target="_blank" rel="noreferrer">Devon Seafood Grill</a>, and crafted a location search in a SQL Server stored procedure.</p>
                <p>During my time as a contractor, Centriq Training offered me an instructor role in the Full Stack Web Developer track. For the past two years, I have taught individuals to make the career change to web development.</p>
                <p>Meanwhile, I have also worked as a freelancer for two small businesses: <a href="https://angelhealthcare.com" target="_blank" className="color">Angel Healthcare</a> and <a href="http://sprocketsburgers.com" target="_blank" className="color">Sprockets Burgers</a>. I would love to be considered for any role where I can continue to grow my skillset, and have sought opportunities to build my technology stack.</p>
                </div>
            </React.Fragment>
            
        };
    }


    render = () =>
        <section className="about">
            <div className="color-band">
                <h3 className="band-heading">Who Am I?</h3>
            </div>
            <div className="container">
            <article>
                {this.state.elevatorPitch}
            </article>
            </div>
        </section>

}

export default About;