const resources = [
    {
        id: `resource${Date.now()}`,
        language: "HTML",
        type: "Client-Side",
        name: "W3Schools",
        url: "https://w3schools.com/html/",
        image: null,
        description: "This is a how-to site for all languages/technologies, but this portion of the site focuses on HTML5 and best practices for creating the site structure and crafting a good functioning Document Object Model."
    },
    {
        id: `resource${Date.now()}`,
        language: "CSS",
        type: "Client-Side",
        name: "W3Schools",
        url: "https://w3schools.com/css/",
        image: null,
        description: "This is a how-to site for all languages/technologies, but this portion of the site focuses on Cascading Stylesheets, and how to best utilize CSS properties to effectively style your website."
    },
    {
        id: `resource${Date.now()}`,
        language: "JavaScript",
        type: "Client-Side",
        name: "W3Schools",
        url: "https://w3schools.com/js/",
        image: null,
        description: "This is a how-to site for all languages/technologies, but this portion of the site focuses on JavaScript and how utilize the Document Object Model to create a fun, interactive UI/UX."
    },
    {
        id: `resource${Date.now()}`,
        language: "C#",
        type: "Server-Side",
        name: "W3Schools",
        url: "https://w3schools.com/c-sharp/",
        image: null,
        description: "This is a how-to site for all languages/technologies, but this portion of the site focuses on C# and best practices to create great server-side functionality."
    },
    {
        id: `resource${Date.now()}`,
        language: "SQL",
        type: "Server-Side",
        name: "W3Schools",
        url: "https://w3schools.com/sql/",
        image: null,
        description: "This is a how-to site for all languages/technologies, but this portion of the site focuses on SQL and how to communicate with and read databases, as well as how to manipulate data."
    }
];

export default resources;