import React from 'react';

class ResourceGallery extends React.Component {
    render = () =>
    <section className="resources">
    <div className="heading-overlay">
      <h1 className="clear-text">Resources</h1>
    </div>
    <div className="resource-content">
    <div className="resource-filter">
    <button onClick={() => this.props.showLang("All Categories")}>All</button><button onClick={() => this.props.showLang("HTML")}>HTML</button>
    <button onClick={() => this.props.showLang("CSS")}>CSS</button>
    <button onClick={() => this.props.showLang("JavaScript")}>JavaScript</button>
    <button onClick={() => this.props.showLang("C#")}>C#</button>
    <button onClick={() => this.props.showLang("SQL")}>SQL</button> 
    <button onClick={() => this.props.showLang("ASP.NET")}>ASP.NET</button> 
    <button onClick={() => this.props.showLang("ReactJS")}>ReactJS</button> 
    </div>
    <h2 className="resource-lang">{this.props.lang}</h2>
    
    <ul className="resource-list">
      {this.props.lang == "All Categories" &&
                Object.keys(this.props.resources).map(key =>
                <li key={this.props.resources[key].url}>
                  <h3><a href={this.props.resources[key].url} target="_blank">{this.props.resources[key].name}</a></h3>
                  <p>{this.props.resources[key].description}</p>
                </li>
                
                )}
        {this.props.lang != "All Categories" &&
            Object.keys(this.props.resources).filter(x => this.props.resources[x].language == this.props.lang).map(key =>
                <li key={this.props.resources[key].url}>
                  <h3><a href={this.props.resources[key].url} target="_blank">{this.props.resources[key].name}</a></h3>
                  <p>{this.props.resources[key].description}</p>
                </li>
                )}
        
        </ul>
        </div>
    </section>
}

export default ResourceGallery;