import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'

class ProjectBrief extends React.Component {

    render = () =>
        <Modal
            dialogClassName="projects-modal"
            show={this.props.showBrief}
            onHide={this.props.onHideBrief}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <h2>Case Brief</h2>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-bg" style={{ backgroundImage: `url(${this.props.image})`}}>
                </div>
                <div className="modal-wrapper" >
                    <h2>{this.props.name}</h2>
                    
                    <p>
                        {this.props.brief}
                    </p>
                    <br/>
                <a href={this.props.url} className="btn btn-dark">Go To Site</a>     
                </div>
               
            </Modal.Body>
           
        </Modal>
}

export default ProjectBrief;