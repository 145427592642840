import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(far);

const LoggedIn = (props) => (
    <section className="log">
        <div className="login">
          <span>Greetings {props.user}!</span>
          <br/>
          <button className="github btn btn-color" onClick={() => props.logout()}>Logout from <FontAwesomeIcon icon={['fab', 'google']}/>oogle</button>
        </div>
    </section>
);

export default LoggedIn;
