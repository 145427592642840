import React from 'react'



class ResumeGallery extends React.Component {
   
render = () => 
    
        <a className={this.props.class} href={this.props.url} target="_blank">
            {this.props.image !== "" &&
            <img src={this.props.image} alt={((this.props.project) + " logo")}/>}
            {this.props.image == "" &&
            <h4>{this.props.project}</h4>
            }
        </a>
    
}

export default ResumeGallery;