import React from 'react';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Resources from './components/Resources';
import NavElement from './components/NavElement';
import Login from './components/Login';
import base, { firebaseApp } from './base';
import firebase from 'firebase';
import sampleResources from './sample-resources';
import sampleProjects from './sample-projects';
import AddResource from './components/AddResource';
import ResourceGallery from './components/ResourceGallery';
import LoggedIn from './components/LoggedIn';
import ProjectAdmin from './components/ProjectAdmin';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: "Home",
      uid: null,
      urole: null,
      uemail: null,
      resources: {},
      projects: {},
      showResources: "Client-Side",
      showType: "All Categories",
      progress: 0,
      
    }
    this.deleteProject = this.deleteProject.bind(this);
  }

  // contentTree = (content) => {
  //   this.setState({ showContent: content});
  // };
  showHome = () => {
    this.setState({ showContent: "Home" });
    window.scrollTo({ top: 0, behavior: 'smooth'});
    
  }

  showProjects = () => {
    this.setState({ showContent: "Projects" });
    window.scrollTo({ top: 0, behavior: 'smooth'});
  }

  showResume = () => {
    this.setState({ showContent: "Resume" })
    window.scrollTo({ top: 0, behavior: 'smooth'});
  }

  showContact = () => {
    this.setState({ showContent: "Contact" })
    window.scrollTo({ top: 0, behavior: 'smooth'});
  }

  showResources = () => {
    this.setState({ showContent: "Resources" })
    window.scrollTo({ top: 0, behavior: 'smooth'});
  }
//Login Functionalities
  authHandler = async (authData) => {

    this.setState({
      uid: authData.user.uid,
      uemail: authData.user.email
    });
    if (this.state.uid === "2HooZr1iJXd9R79dUSFBtCIiixT2" || this.state.uid === "iuOPpvZOcLX55f4ZuiVYEnBFsVJ2")  {
      this.setState({ urole: "Admin"});
    }
    else{
      this.setState({ urole: "Customer"});
    }
     firebase.database().ref('users/' + this.state.uid).set({
        uid : this.state.uid,
        urole: this.state.urole === "Admin" ? "Admin" : "Customer",
        email: this.state.uemail
      });
    
  }
  authenticate = () => {
      const authProvider = new firebase.auth.GoogleAuthProvider();
      firebaseApp.auth().signInWithPopup(authProvider).then(this.authHandler);
  }

  logout = async () => {
    console.log("logging out");
    await firebase.auth().signOut();
    this.setState({ uid: null, urole: null });
  }
  //end login functionality
  //data
  loadSampleResources = () => {
    this.setState({resources: sampleResources});
  }

  loadSampleProjects = () => {
    this.setState({projects: sampleProjects});
  }

  changeFilter = () => {
    this.state.showResources === "Client-Side" ? this.setState({showResources: "Server-Side"}) : this.setState({showResources:"Client-Side"});
  }

  updateResource = (key, updatedResources) => {
    const resources = {...this.state.resources};

    resources[key] = updatedResources;
    this.setState({resources});
  }

  addResource = (resource) => {
    const resources = {...this.state.resources};

    resources[`resource${Date.now()}`] = resource;

    this.setState({ resources });
  }

  deleteResource = (key) => {
    const resources = {...this.state.resources}

    resources[key] = null;

    this.setState({resources});
  }

  
  addProject = project => {
    const projects = {...this.state.projects };

  projects[`projects${Date.now()}`] = project;

  this.setState({ projects });
  } 
  deleteProject = (key) => {
    const projects = {...this.state.projects}

    projects[key] = null;

    this.setState({projects});
  }
  
  updateProject = (key, updatedProjects) => {
    const projects = {...this.state.projects};

    projects[key] = updatedProjects;
    this.setState({projects});
  }

  showLang = (x) => {
    this.setState({ showType: x});
  }

  componentDidMount(){
      const { resources } = this.state.resources;
      this.ref = base.syncState(`/resources`,{
        context: this,
        state: "resources"
      });

      const { projects } = this.state.resources;
      this.ref = base.syncState('/projects', {
        context: this,
        state: "projects"
      });
    }

   
  componentWillUnmount(){
  }
  //end data
  render = () =>
    <div>
      <NavElement home={this.showHome} projects={this.showProjects} resume={this.showResume} resources={this.showResources} contact={this.showContact} />
      <main>
        {this.state.showContent === "Home" &&
          <Home />
        }
        {this.state.showContent === "Projects" && this.state.urole === "Admin" &&
          <div>
          <ProjectAdmin projects={this.state.projects} addProject={this.addProject} role={this.state.urole} deleteProject={this.deleteProject} updateProject={this.updateProject}/>
          
          </div>
        }
        {this.state.showContent === "Projects" && this.state.urole != "Admin" &&
        
          
          <Projects projects={this.state.projects} role={this.state.urole}/>  
          
        }
        {this.state.showContent === "Resume" &&
          <Resume />
        }
        {this.state.showContent === "Contact" &&
          <Contact />
        }
        {this.state.showContent === "Resources" &&
          this.state.urole === "Admin" && 
          <section className="resources">
            <div className="heading-overlay2">
                <h1 className="clear-text">Resource Manager</h1>
           
            <AddResource addResource={this.addResource}/> 
            <br/> 
            <div className="toggles">
              <input type="checkbox" name="styled" id="styled" onChange={this.changeFilter}/>
              <label htmlFor="styled">{this.state.showResources === "Client-Side" ? "Show Server Side" : "Show Client Side"}</label>
            </div> 
            {Object.keys(this.state.resources).filter(key => this.state.resources[key].type === this.state.showResources).map(key =>
              <Resources key={key} index={key} resource={this.state.resources[key]} updateResource={this.updateResource} addResource={this.addResource} deleteResource={this.deleteResource}/>)}
           </div>
           
          </section>
        }
        {this.state.showContent === "Resources" && this.state.urole != "Admin" &&
         
          <ResourceGallery resources={this.state.resources} showLang={this.showLang} lang={this.state.showType}/>
            
        }

        
      
      { this.state.uid == null &&
        <section className="log">
          <Login authenticate={this.authenticate}/>
        </section>
      }


      {
        this.state.uid != null &&
        <LoggedIn logout={this.logout} user={this.state.uemail}/>
      }
      </main>
      <Footer />
    </div>
}

export default App;
