import React from 'react';
import Social from './Social';

class Footer extends React.Component {
    render = () =>
    <footer>
        <Social />
        <p id="copyright">&copy;{new Date().getFullYear()} James R Caldwell</p>        
    </footer>
}

export default Footer;