import React from 'react';

class AddResource extends React.Component {
    nameRef = React.createRef();
    descRef = React.createRef();
    typeRef = React.createRef();
    urlRef = React.createRef();
    languageRef = React.createRef();
    
    createResource = event => {
        event.preventDefault();

        const resource = {
            name: this.nameRef.current.value,
            description: this.descRef.current.value,
            type: this.typeRef.current.value,
            url: this.urlRef.current.value,
            language: this.languageRef.current.value
        };

        this.props.addResource(resource);

        event.currentTarget.reset();
    }
    
    render = () =>
    <div className="resource-add">
        <form className="resourcesForm" onSubmit={this.createResource}>
            <h3 className="text-light">Create New Resource</h3>
            
            <input name="name" ref={this.nameRef} type="text" placeholder="Name" required/>
           
            <input type="text" name="url" ref={this.urlRef} placeholder="URL" required/>
            <select name="language" ref={this.languageRef}>
                <option value="HTML">HTML</option>    
                <option value="CSS">CSS</option>    
                <option value="JavaScript">JavaScript</option>    
                <option value="SQL">SQL</option>    
                <option value="ASP.NET">ASP.NET</option>    
                <option value="C#">C#</option>    
                <option value="ReactJS">ReactJS</option>        
            </select> 
            <select name="type" ref={this.typeRef}>
                <option value="Client-Side">Client Side</option>
                <option value="Server-Side">Server Side</option>    
            </select>
            <br/>
            <textarea name="description" ref={this.descRef} placeholder="Enter Description" required/> 
      
            <button type="submit" className="btn btn-outline-light">Add Resource</button>  
        </form> 
    </div>
}

export default AddResource;