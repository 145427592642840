import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import firebase from 'firebase';
import base, {firebaseApp} from '../base';

library.add(fas, far, fab);
console.log(firebaseApp);

class Resources extends React.Component {
    handleChange = event => {
        console.log(event.currentTarget.value);
    
        const updateResource = {...this.props.resources, [event.currentTarget.name] : event.currentTarget.value};
        this.props.updateResource(this.props.index, updateResource);
      };

    render = () =>
        
        <article className="resource-item">
            <button className="delete" onClick={() => this.props.deleteResource(this.props.index)} title="Delete Link">
                &times;
            </button>
            <h2><a href={this.props.resource.url} target="_blank">{this.props.resource.name}</a></h2>
            
            <label id="name">Name</label>
            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.props.resource.name} />
           
           
            <label id="url">URL</label>
            <input type="text" name="url" className="form-control" onChange={this.handleChange} value={this.props.resource.url} />
            
            
           <label id="desc">Desc</label>
            <textarea name="description" className="form-control" onChange={this.handleChange} value={this.props.resource.description}></textarea>
           
           <label>Type</label>
           <select name="type" className="form-control" onChange={this.handleChange} value={this.props.resource.type}>
               <option value="Client-Side">Client-Side</option>
               <option value="Server-Side">Server-Side</option>
           </select>

           <label>Tech</label>
           <select name="language" className="form-control" onChange={this.handleChange} value={this.props.resource.language}>
               <option value="HTML">HTML</option>
               <option value="CSS">CSS</option>
               <option value="JavaScipt">JavaScript</option>
               <option value="C#">C#</option>
               <option value="SQL">SQL</option>
               <option value="ASP.NET">ASP.NET</option>
               <option value="ReactJS">ReactJS</option>
           </select>
        </article>
}

export default Resources;