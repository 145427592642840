import React, { Component } from 'react'
import axios from 'axios'
import { firebaseApp, storage } from '../base'
import SingleProject from './SingleProject'
import ProgressBar from 'react-bootstrap/ProgressBar'

export default class ProjectAdmin extends Component {
    nameRef = React.createRef();
    descRef = React.createRef();
    techRef = React.createRef();
    siteRef = React.createRef();
    briefRef = React.createRef();
    fileRef = React.createRef();
    //file, imageURL, progress for image
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            name: '',
            siteURL: '',
            file: '',
            tech: '',
            brief: '',
            desc: '',
            image: '',
            id: '',
            now: 0
        }
    }

    createProject = event => {
        event.preventDefault();
        const project = {
            name: this.nameRef.current.value,
            siteURL: this.siteRef.current.value,
            file: this.fileRef.current.files[0],
            tech: this.techRef.current.value,
            brief: this.briefRef.current.value,
            desc: this.descRef.current.value,
            image: '',
            id: ''
        }
        this.setState({
            name: project.name,
            siteURL: project.siteURL,
            file: project.file,
            tech: project.tech,
            brief: project.brief,
            desc: project.desc
        })
        console.log(project);
        const uploadTask = storage.ref('uploads').child(project.file.name).put(project.file);
        uploadTask.on("state_changed", (snapshot) => {
            //Progress
            var progressTrack = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({ progress: progressTrack });
            if (this.state.progress == 100) {
                this.showPreview();


            }
        }, console.error, () => {
            storage.ref('uploads').child(project.file.name).getDownloadURL()
                .then((image) => {
                    console.log(image);
                    //add db logic here 

                    project.image = image;
                    project.id = `projects${Date.now()}`;
                    this.setState({ image: project.image, id: project.id })
                    this.props.addProject(project);
                    event.target.reset();
                });
        })
    }

    showPreview = () => {
        console.log("Preview Shown");
    }

    deleteProject = (e) => {
        this.props.deleteProject(e);
    }

    updateProject = () => {
        this.props.updateProject();
    }
    componentDidMount = () => {

    }
    render() {
        return (
            <section className="projects">
                <div className="heading-overlay">
                    <h1 className="clear-text">Projects</h1>
                </div>
                <div className="create-container">
                    <div className="form-container">
                        <form className="projectsForm" onSubmit={this.createProject}>
                            <h2>Create New Project</h2>
                            <div className="form-group">
                                <input name="name" ref={this.nameRef} type="text" placeholder="Name" required />
                            </div>
                            <div className="form-group">
                                <input name="url" ref={this.siteRef} type="text" placeholder="Website URL" required />
                            </div>
                            <div className="form-group">
                                <input name="file" ref={this.fileRef} type="file" required />

                            </div>
                            <div className="form-group">
                                <select className="" ref={this.techRef}>
                                    <option value="ASP.NET MVC">ASP.NET MVC</option>
                                    <option value="ReactJS">ReactJS</option>
                                    <option value="CMS">CMS</option>
                                    <option value="SQL">SQL Server</option>
                                    <option value="C#">C#</option>
                                    <option value=".NET CORE">.NET CORE</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <textarea name="brief" className="taProjects" ref={this.briefRef} placeholder="Use Case"></textarea>
                            </div>
                            <div className="form-group">
                                <textarea name="description" className="taProjects" ref={this.descRef} placeholder="Description"></textarea>
                            </div>
                            <button type="submit" className="btn btn-dark">Submit</button>

                        </form>
                    </div>

                    <article className="projectAdd gallery" id="preview">
                        {this.state.progress < 100 &&
                            <div>
                                <h2>See Your Creation...</h2>
                                <hr />
                                <h3 className="upload-placeholder">Upload to Preview</h3>
                                <div className="project-upload"></div>
                            </div>
                        }
                        {this.state.progress == 100 &&
                            <div>
                                <h2>See Your Creation...</h2>
                                <hr />
                               
                        <SingleProject projects={this.props.projects} name={this.state.name} tech={this.state.tech} url={this.state.siteURL} image={this.state.image} brief={this.state.brief} now={0} role="Customer" deleteProject={this.props.deleteProject} updateProject={this.props.updateProject} />
                    
                            </div>


                        }
                        {this.state.progress != 0 &&

                            <div className="project-upload">
                                <ProgressBar striped variant="dark" now={this.state.progress} label={`${this.state.progress}% uploaded`} />
                            </div>
                        }
                    </article>
                </div>



                <article className="gallery">
                    <h2>Project Gallery</h2>
                    <hr />
                    {Object.keys(this.props.projects).map(key =>
                        <SingleProject projects={this.props.projects} key={this.props.projects[key].id} index={key} name={this.props.projects[key].name} tech={this.props.projects[key].tech} url={this.props.projects[key].siteURL} brief={this.props.projects[key].brief} image={this.props.projects[key].image} now={0} role={this.props.role} deleteProject={this.props.deleteProject} updateProject={this.props.updateProject} />
                    )}

                </article>
            </section>
        )
    }
}
