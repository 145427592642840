import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

class References extends React.Component {

    render = () =>
        <article className="references">
            <h2>References</h2>
            <div className="reference-gallery">
                <h3 className="under-construction">Under Construction...<br/>Check back soon!</h3>
                {/* <div className="single-reference">
                    <h3>Jenna Beckett <a href="" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></h3>
                    <h4 className="text-center font-italic ref-tag">Full Stack Web Developer Track Team Lead &#64; Centriq Training</h4>
                    <div className="reference-answers">
                        <h4>How do I know James?</h4>
                        <p>I taught James at Centriq while he was making the career change, and also had the privilege of working with him when he started as an instructor at Centriq.</p>
                        <h4>What are James' strengths?</h4>
                        <p>James is a diligent worker who is not afraid to say when he doesn't know. However, he will persevere until he finds an answer.</p>
                        <p>He also works well with teams, collaborating and adding value to the teams he works on. James is good at finding a role that is of need and assuming that role for the success of the team.</p>
                        <h4>What is an example of a tough situation where James' talents shined?</h4>
                        <p>James had a difficult task ahead of him in teaching ReactJS under a very small window of time learning React. He spent the time to learn the content well, and has become one of the go-to instructors when it comes to React.</p>
                    </div>
                </div>
                <div className="single-reference">
                    <h3>Jeff DeMaranville <a href="" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></h3>
                    <h4 className="text-center font-italic ref-tag">Full Stack Web Developer Track Instructor &#64; Centriq Training</h4>
                    <div className="reference-answers">
                        <h4>How do I know James?</h4>
                        <p>I taught James at Centriq while he was making the career change, and also had the privilege of working with him when he started as an instructor at Centriq.</p>
                        <h4>What are James' strengths?</h4>
                        <p>James is a diligent worker who is not afraid to say when he doesn't know. However, he will persevere until he finds an answer.</p>
                        <p>He also works well with teams, collaborating and adding value to the teams he works on. James is good at finding a role that is of need and assuming that role for the success of the team.</p>
                        <h4>What is an example of a tough situation where James' talents shined?</h4>
                        <p>James had a difficult task ahead of him in teaching ReactJS under a very small window of time learning React. He spent the time to learn the content well, and has become one of the go-to instructors when it comes to React.</p>
                    </div>
                </div>
                <div className="single-reference">
                    <h3>Andrea Pedersen <a href="" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></h3>
                    <h4 className="text-center font-italic ref-tag">Director of Career Operations &#64; Centriq Training</h4>
                    <div className="reference-answers">
                        <h4>How do I know James?</h4>
                        <p>I taught James at Centriq while he was making the career change, and also had the privilege of working with him when he started as an instructor at Centriq.</p>
                        <h4>What are James' strengths?</h4>
                        <p>James is a diligent worker who is not afraid to say when he doesn't know. However, he will persevere until he finds an answer.</p>
                        <p>He also works well with teams, collaborating and adding value to the teams he works on. James is good at finding a role that is of need and assuming that role for the success of the team.</p>
                        <h4>What is an example of a tough situation where James' talents shined?</h4>
                        <p>James had a difficult task ahead of him in teaching ReactJS under a very small window of time learning React. He spent the time to learn the content well, and has become one of the go-to instructors when it comes to React.</p>
                    </div>
                </div>
                <div className="single-reference">
                    <h3>Duane Slaughbaugh <a href="" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></h3>
                    <h4 className="text-center font-italic ref-tag">Developer &#64; Houlihans Restaurants Inc.</h4>
                    <div className="reference-answers">
                        <h4>How do I know James?</h4>
                        <p>I taught James at Centriq while he was making the career change, and also had the privilege of working with him when he started as an instructor at Centriq.</p>
                        <h4>What are James' strengths?</h4>
                        <p>James is a diligent worker who is not afraid to say when he doesn't know. However, he will persevere until he finds an answer.</p>
                        <p>He also works well with teams, collaborating and adding value to the teams he works on. James is good at finding a role that is of need and assuming that role for the success of the team.</p>
                        <h4>What is an example of a tough situation where James' talents shined?</h4>
                        <p>James had a difficult task ahead of him in teaching ReactJS under a very small window of time learning React. He spent the time to learn the content well, and has become one of the go-to instructors when it comes to React.</p>
                    </div>
                </div>*/}
            </div> 
        </article>
}

export default References;