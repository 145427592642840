import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {storage} from '../base'
import ProgressBar from 'react-bootstrap/ProgressBar'

library.add(fas, far, fab);

export default class EditProject extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showUpload : false,
            image: this.props.projects.image,
            progress: 0
        }
    }
    fileRef = React.createRef();

    updateProject = e =>{
        this.props.updateProject(e);
    }
    
        handleChange = event => {
            event.preventDefault();

            console.log(event.currentTarget.value);
        
            const updateProject = {...this.props.projects, 
                [event.currentTarget.name] : event.currentTarget.value
            };
            this.props.update(this.props.index, updateProject);
          }

        

        showUpload = () => {
          this.state.showUpload === false ? this.setState({showUpload : true}) : this.setState({showUpload : false})
        }

        newImage = file => {
        const image = this.fileRef.current.files[0];

       
        
        const uploadTask = storage.ref('uploads').child(this.props.projects.name).put(image);
        uploadTask.on("state_changed", (snapshot) => {
            //Progress
            var progressTrack = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({ progress: progressTrack });
            
        }, console.error, () => {
            storage.ref('uploads').child(this.props.projects.name).getDownloadURL()
                .then((url) => {
                    console.log(url);
                    //add db logic here 
                    const updateProject = {...this.props.projects, image: url};
                    this.props.update(this.props.index, updateProject);
                    this.setState({ image : url});
                    
                    
                });

        })
     
        }

  
    render = () => 
        <Modal
        dialogClassName="projects-modal"
        show={this.props.show} 
        onHide={this.props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="project-modal" closeButton>
          <h3>Edit {this.props.projects.name}</h3>
      </Modal.Header>
      <Modal.Body className="projectsForm">
          <div className="img-container">
            <img className="img-fluid" src={this.state.image} alt={this.props.projects.name} />
            <div className="upload-button" onClick={this.showUpload}>
              <FontAwesomeIcon icon={['fas', 'arrow-circle-up']} />
            </div>
           
          </div>
          {this.state.progress != 0 &&
                    
                    
                    <ProgressBar striped variant="dark" now={this.state.progress} label={`${this.state.progress}% uploaded`}/>
                    }
          <div className="form-group">
          {this.state.showUpload === true &&
            <div className="upload-input">
            <label>Upload New Image</label>
            <br/>
            <input name="file" type="file" ref={this.fileRef} onChange={this.newImage}/> 
            
            </div>
            }
          </div>
          <div className="form-group">
         
          <label>Project Name</label>
          <input type="text" name="name" className="form-control" value={this.props.projects.name} onChange={this.handleChange}/>
          </div>
          <div className="form-group">
          <label>Site URL</label>
          <input type="text" name="siteURL" className="form-control" value={this.props.projects.siteURL} onChange={this.handleChange}/>
          </div>
          <div className="form-group">
            <label>Technology</label>
            <select name="tech" value={this.props.projects.tech} className="form-control" onChange={this.handleChange}>
                <option value="ASP.NET MVC">ASP.NET MVC</option>
                <option value="ReactJS">ReactJS</option>
                <option value="CMS">CMS</option>
                <option value="SQL">SQL Server</option>
                <option value="C#">C#</option>
                <option value=".NET CORE">.NET CORE</option>
            </select>
          </div>
          <div className="form-group">
          <label>Case Brief</label>
          <textarea name="brief" className="form-control" value={this.props.projects.brief} onChange={this.handleChange}></textarea>
          </div>
          <div className="form-group">
          <label>Description</label>
          <textarea name="desc" className="form-control" value={this.props.projects.desc} onChange={this.handleChange}></textarea>
          </div>
      </Modal.Body>
   </Modal>
    }
