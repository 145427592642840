import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCaptcha from 'react-recaptcha'

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const [capPass, setCapPass] = useState(false);

    let recaptchaInstance;

    const recaptchaReset = () => {
        recaptchaInstance.reset();
    }
    const recapthcaLoaded = (response) => {
        if(response){
            setCapPass(true);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();

            setLoader(true);
            if(capPass == true){
                alert("Message has been submitted.");
            emailjs.send('gmail', 'gmailTemplate', {
                name: name,
                email: email,
                message: message,
                reply_to: email
            },
                'user_XCYeGm6H2BMHIOpeusLLW'
            ).catch((error) => {
                alert(error.message);
                setLoader(false);
            });;
            e.target.reset();
            setLoader(false);
            setName('');
            setEmail('');
            setMessage('');
            recaptchaReset();
            setCapPass(false);
            }
            else{
                alert('Please prove you are a human!');
            }
    }

   
    return (
        <section className="contact">
            <div className="container-fluid">
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        <form className="contact-form" onSubmit={handleSubmit}>
                            <h1>Contact Form</h1>
                            <p className="bold">Send a message and I will get back to you ASAP.</p>
                            <div className="form-group">
                                <input placeholder="Name" className="form-control rounded" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input placeholder="Email" className="form-control rounded" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <textarea placeholder="Message" className="form-control rounded" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <div className="form-group">
                                <ReCaptcha
                                ref={e => recaptchaInstance = e}
                                sitekey="6LcjJWYaAAAAAOPQe3ydLgeOLNJPTElIcCbXo7Z_"
                                render="explicit"
                                verifyCallback={recapthcaLoaded}
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-dark rounded">Send Message</button>
                            </div>

                        </form>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Contact;
