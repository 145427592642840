import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBcJF8npZdqbf7IjVxoKJpxJaWSc2TFAhg",
    authDomain: "personalsite-4fd26.firebaseapp.com",
    databaseURL: "https://personalsite-4fd26-default-rtdb.firebaseio.com",
    projectId: "personalsite-4fd26",
    storageBucket: "personalsite-4fd26.appspot.com",
    messagingSenderId: "785473010661",
    appId: "1:785473010661:web:f2c1287aa4d578816e4d0c",
    measurementId: "G-6003QNQDF3"
});

const base = Rebase.createClass(firebaseApp.database());
const storage = firebaseApp.storage();


export {firebaseApp};
export {storage};
export default base;

