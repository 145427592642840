import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)

class Social extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="social">
                <h3 className="light"><FontAwesomeIcon icon={['fas', 'hashtag']} />getsocial <a href="https://www.linkedin.com/in/james-caldwell-686042138/" className="light" target="
                _blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                    <a href="https://github.com/jamescaldwell82?tab=repositories" className="light" target="_blank"><FontAwesomeIcon icon={['fab', 'github']} /></a>
                    </h3>

            </div>
        </React.Fragment>
}

export default Social;