import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import image from '../images/JRCTECH-logo-invert.png';

class NavElement extends React.Component {

  imgStyle = {
    width: "60px"
  }
  render = () =>
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="md" fixed="top">
      <Navbar.Brand href="/"><img src={image} alt="JRC Dev logo" style={this.imgStyle} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="justify-content-end text-center" defaultActiveKey="#home">
          <Nav.Link as="li" href="#home" onClick={this.props.home}>Home</Nav.Link>
          <Nav.Link as="li" href="#projects" onClick={this.props.projects}>Projects</Nav.Link>
          <Nav.Link as="li" href="#resume" onClick={this.props.resume}>Resum&eacute;</Nav.Link>
          <Nav.Link as="li" href="#resources" onClick={this.props.resources}>Resources</Nav.Link>
          <Nav.Link as="li" href="#contact" onClick={this.props.contact}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
}

export default NavElement;