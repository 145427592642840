import React from 'react';
import TechSkills from './TechSkills';
import References from './References';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import ProExperience from './ProExperience';
import Education from './Education';
import Volunteer from './Volunteer';


// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)


      
class Resume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSection: "skills"
        }
    }
    showSkills = () => {
        this.setState({ showSection: "skills" });   
    }

    showPro = () => {
        this.setState({ showSection: "professional"})
    }
    showEd = () => {
        this.setState({ showSection: "education"})
    }

    showVol = () => {
        this.setState({ showSection: "volunteer" })
    }
    showRefs = () => {
        this.setState({ showSection: "references" })

    }

    
        
    
    render = () =>

        <section className="resume">
            <div id={this.state.showSection} className="anchor"></div>
            <div className="heading-overlay">
                <h1 className="clear-text">Resum&eacute;</h1>
                
            </div>
            
            <nav className="submenu">
                <ul>
                    <li>
                        <a href="#skills" onClick={this.showSkills} className="section-link">Tech Skills</a>
                    </li>
                    <li>
                        <a href="#professional" onClick={this.showPro} className="section-link">Professional Experience</a>
                    </li>
                    <li>
                        <a href="#education" onClick={this.showEd} className="section-link">Educational Experience</a>
                    </li>
                    <li>
                        <a href="#volunteer" onClick={this.showVol} className="section-link">Volunteer Experience</a>
                    </li>
                    <li>
                        <a href="#references" onClick={this.showRefs} className="section-link">References</a>
                    </li>
                </ul>
            </nav>
            {this.state.showSection == "skills" &&
                <TechSkills />
            }
            {this.state.showSection == "professional" &&
               <ProExperience />
            }
            {this.state.showSection == "education" &&
                <article className="education">
                    <Education />
                </article>
            }
            {this.state.showSection == "volunteer" &&
               <Volunteer/>
            }
            {this.state.showSection == "references" &&
                <References />
            }
        </section>
}

export default Resume;