import React from 'react';
import './ResumeGallery';
import ResumeGallery from './ResumeGallery';
import houImage from '../images/logos/Houli_logo_white.png';
import bristolImage from '../images/logos/Bristol_Logo.png';
import devonImage from '../images/logos/Devon_Logo.png';
import fsdpImage from '../images/logos/LightHouse.png';
import sprocketsImage from '../images/logos/SprocketLogo-WhiteTransparent.png';



class ProExperience extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            houProjects: [
                {
                    name: "Houlihans",
                    company: "hou",
                    class: "houlihans",
                    image: houImage,
                    description: "Using a SQL Server Stored Procedure, I created the logic to take in a customer's location and populate a list of Houlihans restaurants that were closest to them. In this project, I utilized a stored procedure in a SQL Server database, and called that stored procedure from an MVC 5 Controller in an ASP.NET MVC application. The action in the controller returns JSON, which is then used to populate the location search results.",
                    url: "http://hou.mx/HOU/SearchLocation?userInput=Kansas"
                },
                {
                    name: "Bristol Seafood Grill",
                    company: "hou",
                    class: "bristol",
                    image: bristolImage,
                    description: "Using a paid version of DotNetNuke called Evoq, I crafted websites for each of the Bristol Seafood Grill locations throughout the country. I created the modules necessary to allow the marketing department to update their website in more effortless fashion. In this project, I utilized Evoq, HTML, CSS, and JavaScript to craft their websites. I also used tools such as Wave to ensure that the sites were ADA compliant and SEO-friendly.",
                    url: "https://bristolseafoodgrill.com"
                },
                {
                    name: "Devon Seafood + Steak",
                    company: "hou",
                    class: "devon",
                    image: devonImage,
                    description: "Using a paid version of DotNetNuke called Evoq, I crafted websites for each of the Devon Seafood locations throughout the country. I created the modules necessary to allow the marketing department to update their website in more effortless fashion. In this project, I utilized Evoq, HTML, CSS, and JavaScript to craft their websites. I also used tools such as Wave to ensure that the sites were ADA compliant and SEO-friendly.",
                    url: "https://devonseafood.com"
                },
                {
                    name: "Angel Health",
                    company: "angel",
                    class: "angel",
                    image: "",
                    description: "Built a website for a home healthcare service located in Texas. This site is built in an ASP.NET MVC 5 application, tied to a SQL Server database and uses Entity Framework as an ORM. Key custom functionalities include the Request for Service form, where a user can submit a request and that request is communicated to certain individuals. As well, this site is an SEO-friendly and mobile responsive design/layout.",
                    url: "https://angelhealthcare.com"
                },
                {
                    name: "Sprockets",
                    company: "sprockets",
                    class: "sprockets",
                    image: sprocketsImage,
                    description: "Built a small website for a startup restaurant in Salida, Colorado. This is an ASP.NET MVC Web Application tied to a SQL Server database through an Entity Framework ORM. Key functionalities include an AJAX Email Signup form, CRUD functionality for menu items on the site, and a functional contact form for customers to reach the company.",
                    url: "http://sprocketsburgers.com"
                },
                {
                    name:"Hat Trick Hockey",
                    company: "centriq-student",
                    class: "commerce",
                    image: "",
                    description: "Using ASP.NET MVC 5, I created an e-commerce site which is driven by a SQL Server database. In this project, I used Entity Framework and created many custom functionalities. Highlighted functionalities include Image Upload, Session-based Shopping Cart, and filtered views based on product attributes. Also included in functionalities are role-based authorization and backend (C#) validation to protect the integrity of the data.",
                    url: "http://ustore.jamesrcaldwell.com"
                },
                {
                    name:"Lighthouse",
                    company: "centriq-student",
                    class: "fsdp",
                    image:  fsdpImage,
                    description: "Using ASP.NET MVC 5, I created a Reservation System which is driven by a SQL Server database. In this project, I used Entity Framework and created many custom functionalities. Highlighted functionalities include Image Upload, Model manipulation to improve the UX, and limiting the UI based on the user's role. Also included in functionalities are role-based authorization and backend (C#) validation to protect the integrity of the data.",
                    url: "http://fsdp.jamesrcaldwell.com"
                },
                {
                    name:"Dungeon Crawler",
                    company: "centriq-student",
                    class: "dungeon",
                    image:  "",
                    description: "Creating a C# Console Application, I created a Dungeon Crawler that exhibits my understanding of key C#/OOP principles. Concepts such as creating custom class, inheritance, polymorphism, custom methods, and collections were reinforced through my construction of this application.",
                    url: "#"
                }
            ]
        }
    }

    renderProjects = (company) => {
        return this.state.houProjects.filter(x => x.company == company).map((item, index) => 
            <ResumeGallery key={index} project={item.name} url={item.url} class={item.class} image={item.image} />
        );
    }
    render = () =>
        <article className="professional">
            <h2>Professional Experience</h2>
            <div className="single-reference centriq">
                <h3>Centriq Training<br/><span>[April 2019 - Current]</span></h3>
                <h4 className="text-center font-italic ref-tag">Full Stack Web Developer Track Instructor</h4>
                <div className="reference-answers">
                    <ul>
                        <li>Excelling as an instructor teaching students to transition into IT careers as web developers</li>
                        <li>Growing my instructing skills for team management in the classroom, enhancing my skills as a leader to give the best possible product to my clients</li>
                        <li>Facilitating and crafting changes for curriculum to keep up with times</li>
                        <li>Learning new technologies (ReactJS, Node.js, etc.) as needed to teach our curriculum</li>
                        <li>Creating training videos to aid in the virtual training environment</li>
                        <li>
                            Growing in my debugging abilities as a developer, with many opportunities to better understand the inner workings of the programming languages/technologies we are teaching
                            </li>
                        <li>Earned Q2 Career Track Instructor of the Quarter Award in 2020 for exemplary performance</li>
                    </ul>
                </div>
                <div className="text-center">
                  <section className="resume-gallery">
                        {this.renderProjects('centriq')}
                  </section>
                </div>
            </div>
            <div className="single-reference houlihans">
                <h3>Houlihans Restaurants Inc.<br/><span>[Sept 2018 - Mar 2019]</span></h3>
                <h4 className="text-center font-italic ref-tag">Front End Developer Contractor</h4>
                <div className="reference-answers">
                    <ul>
                        <li>Excelling as an instructor teaching students to transition into IT careers as web developers</li>
                        <li>Growing my instructing skills for team management in the classroom, enhancing my skills as a leader to give the best possible product to my clients</li>
                        <li>Facilitating and crafting changes for curriculum to keep up with times</li>
                        <li>Learning new technologies (ReactJS, Node.js, etc.) as needed to teach our curriculum</li>
                        <li>Creating training videos to aid in the virtual training environment</li>
                        <li>
                            Growing in my debugging abilities as a developer, with many opportunities to better understand the inner workings of the programming languages/technologies we are teaching
                            </li>
                        <li>Earned Q2 Career Track Instructor of the Quarter Award in 2020 for exemplary performance</li>
                    </ul>
                </div>
                <div className="text-center">
                    <section className="resume-gallery">
                        {this.renderProjects('hou')}
                    </section>
                </div>
            </div>
            <div className="single-reference angel">
                <h3>Angel Healthcare<br/><span>[Aug 2020 - Nov 2020]</span></h3>
                <h4 className="text-center font-italic ref-tag">Freelance Full Stack Developer</h4>
                <div className="reference-answers">
                    <ul>
                        <li>Built a website for a home healthcare company located in Texas</li>
                        <li>Website architecture: ASP.NET MVC Web Application, SQL Server, Entity Framework</li>
                        <li>Successfully gathered the client's requirments and organized the development/deployment for this application</li>
                        <li>Request Service Form - sends email to appropriate party in the company when a user sends a request</li>
                        <li>Mobile Responsive Design utilizing CSS Media Queries/JavaScript implementations to create a mobile responsive design/layout</li>
                        <li>SEO-friendly implementations using appropriate metatags and content to match, as well as creating an ADA-compliant design</li>
                    </ul>
                </div>
                <div className="text-center">
                    <section className="resume-gallery">
                        {this.renderProjects('angel')}
                    </section>
                </div>
            </div>
            <div className="single-reference sprockets">
                <h3>Sprockets Burgers<br/><span>[May 2019 - Dec 2019]</span></h3>
                <h4 className="text-center font-italic ref-tag">Freelance Full Stack Developer</h4>
                <div className="reference-answers">
                    <ul>
                        <li>Built a website for a startup restaurant in Salida, Colorado</li>
                        <li>Website architecture: ASP.NET MVC Web Application, SQL Server, Entity Framework</li>
                        <li>Successfully gathered the client's requirments and organized the development/deployment for this application</li>
                        <li>Full CRUD functionality for menu items to display on their site.</li>
                        <li>Created a mini-CMS system utilizing the database to make it possible for the client to update their Hours and About sections.</li>
                        <li>SEO-friendly implementations using appropriate metatags and content to match, as well as creating an ADA-compliant design</li>
                    </ul>
                </div>
                <div className="text-center">
                    <section className="resume-gallery">
                        {this.renderProjects('sprockets')}
                    </section>
                </div>
            </div>
        </article>
}

export default ProExperience;